import './MapPopup.css';
import { Tooltip } from "react-leaflet";
import { currencyFormatter, numberFormatter } from '../../../services/Utilities';
import { ReactComponent as ComplaintIcon } from '../../../assets/complaint.svg';
import { ReactComponent as FundsIcon } from '../../../assets/funds.svg';
import { ReactComponent as ProjectsIcon } from '../../../assets/projects.svg';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Popover from 'react-bootstrap/Popover'


export default function MapPopup({ properties }){

    let countryName = properties['name'];
    let totalInvestments = properties["totalFundedInvestmentsInUsd"] || 0.0;
    let totalNumProjects = properties["totalNumFundedProjects"] || 0;
    let totalNumComplaints = properties["totalNumComplaints"] || 0;

    return (
        <Tooltip className="mapPopup text-wrap">
            <Container>
                <Row>
                    <h5><b>{countryName}</b></h5>
                </Row>
                <Row>
                    <Col sm={4}>
                        <ProjectsIcon />
                    </Col>
                    <Col sm={8}>
                        <p className="popupMetric">
                            {numberFormatter.format(totalNumProjects)}
                        </p>
                        <p className="popupMetricLabel">funded projects</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FundsIcon />
                    </Col>
                    <Col md={8}>
                        <p className="popupMetric">
                            {currencyFormatter.format(totalInvestments)}
                        </p>
                        <p className="popupMetricLabel">total funds received</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <ComplaintIcon /> 
                    </Col>
                    <Col sm={8}>
                        <p className="popupMetric">
                            {numberFormatter.format(totalNumComplaints)}
                        </p>
                        <p className="popupMetricLabel">complaints</p>
                    </Col>
                </Row>
            </Container>
        </Tooltip >
    );
}; 