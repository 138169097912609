import './InvestmentPage.css';
import DataTable from '../../investments/table/DataTable';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


export default function ProjectPage() {

    return (
        <>
            <Container className='projects-container'>
                <h1 className='header'>Shareholder Tracker</h1>
                <Row>
                    <Col>
                        <p className='subtext'>
                            This database compiles the shareholdings of 80 of the largest institutional
                            investors in the world. These investors disclose their shareholdings in
                            publicly traded companies to the U.S. Securities and Exchange Commission on
                            Form 13F, which is filed on a quarterly basis.
                        </p>
                        <p className='subtext'>
                            To search for a company’s shareholders, type in the name of the company in
                            either the main Search bar or the Company filter column. If you know the
                            company’s ticker symbol or the CUSIP number for a particular share class,
                            you can filter by those, too.
                        </p>
                        <p className='subtext'>
                            All search results can be sorted in ascending or descending order by
                            clicking on the appropriate column heading. For instance, if you want
                            to sort by Report Period, click on that heading to toggle between ascending
                            and descending order.
                        </p>
                        <p className='subtext'>
                            The database collects 23 types of information from each 13F Form. The
                            default view shows six columns of information. To add other columns of
                            data not shown in the default view, click on the “eye” icon next to the
                            main search bar. To see the original Form 13F on the Securities and Exchange
                            Commission website, double click on a row in the search results.
                        </p>
                        <p className='subtext'>
                            NOTE: This page is currently under development. We will be optimizing
                            the user experience and adding new investors over time.
                        </p>
                        <DataTable></DataTable>
                        <br/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
