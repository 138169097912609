import Plot from 'react-plotly.js';

export default function SectorProjectBarPlot({ sectors }){

  return <Plot
      data={[
        {
          x: sectors.map(s => s['total_num_funded_projects']).reverse(),
          y: sectors.map(s => s['name']).reverse(),
          name: 'Total Number of Funded Projects',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: 'rgb(255,255,0)' 
          }
        },
      ]}
      layout={{
          height: 400,
          width: 600,
          showlegend: false,
          margin: {
            l: 250,
            r: 50,
            b: 50,
            t: 10,
            pad: 4
          }
      }}
    />
}