import './BigLoader.css';
import Spinner from 'react-bootstrap/Spinner'

export default function BigLoader({loadingText}) {
    return (
        <div className="loaderOuterContainer">
            <div className="loaderInnerContainer">
                <Spinner className="loader" animation="border" variant="primary" size="lg" />
                <br/>
                <h3 className="loaderText">{loadingText}</h3>
            </div>     
        </div>
    )
}