import Plot from 'react-plotly.js';
import Plotly from 'react-plotly.js';

export default function BankFundingLinePlot({ banks }){
  let traces = [];
  let colors = [
    '#800080',
    '#FF0000',
    '#0000FF',
    '#FF4500',
    '#00FF00',
    '#008080',
    '#FFFF00',
    '#800000',
    '#800080',
    '#808000',
    '#FA8072',
    '#D2691E',
    '#87CEEB',
    '#7CFC00',
    '#800000',
    '#CD5C5C',
    '#708090'
  ]

  for (let i = 0; i < banks.length; i++){
    let bankFunds = banks[i]['funding_by_year_in_usd'];
    traces.push({
        x: bankFunds.map(f => f['year']),
        y: bankFunds.map(f => f['total_amount']),
        name: banks[i]['name'],
        mode: 'lines+markers',
        line: {
          color: colors[i],
          width: 2
        }
    })
  }

  return <Plot
      data={traces}
      layout={{
          xaxis: {
            title: '<b>Year</b>', 
            font: {
                family: 'Arial',
            },
            range: [2010, 2022],
            zeroline: false,
            showline: true,
            showgrid: true,
            showticklabels: true,
            linecolor: 'rgb(204,204,204)',
            linewidth: 2,
            autotick: false,
            ticks: 'outside',
            tickcolor: 'rgb(204,204,204)',
            tickwidth: 2,
            ticklen: 5,
            tickfont: {
              family: 'Arial',
              size: 12,
              color: 'rgb(82, 82, 82)'
            }
          },
          yaxis: {
            title: '<b>Total Funding in Current USD ($)</b>',
            zeroline: false
          },
     
          height: 400,
          width: 1200,
          showlegend: true,
          margin: {
            l: 60,
            r: 50,
            b: 80,
            t: 10,
            pad: 4
          },
          legend: {
            title: '<b>registration status</b>',
            x: 1.0,
            y: 0.5,
            bgcolor: 'rgba(255, 255, 255, 0)',
            bordercolor: 'rgba(255, 255, 255, 0)',
          },
      }}
    />
}

