/**
 * Utilities.
 *
 * Helper functions and classes used across the web app.
 *
 * @author Launa Greer
 * @since  08.17.2021
 */

export const currencyFormatter =  new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});