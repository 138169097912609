import './AboutPage.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import complaintsPageIcon from '../../assets/complaints_page_icon.png';
import databasePageIcon from '../../assets/database_page_icon.png';
import landingPageCollage from '../../assets/landing_page_collage.png';
import Link from '../common/link/Link';


export default function AboutPage() {

    const bankNames = [
        'African Development Bank (AfDB)',
        'Asian Development Bank (ADB)',
        'Asian Infrastructure Investment Bank (AIIB)',
        'Belgian Investment Company for Developing Countries (BIO)',
        'Deutsche Investitions - und Entwicklungsgesellschaft (DEG)',
        'European Bank for Reconstruction and Development (EBRD)',
        'European Investment Bank (EIB)',
        'Inter-American Development Bank (IDB)',
        'International Finance Corporation (IFC)',
        'Kreditanstalt für Wiederaufbau (KfW)',
        'Multilateral Investment Guarantee Agency (MIGA)',
        'Nederlandse Financierings-Maatschappij voor Ontwikkelingslanden (FMO)',
        'Norges Bank Investment Management (NBIM)',
        'U.S. International Development Finance Corporation (DFC)',
        'Proparco',
        'United Nations Development Programme (UNDP)',
        'World Bank (WB)'
    ];

    const lengthFirstNameCol = Math.trunc(bankNames.length / 2);

    return (
        <Container className='about-container'>
            <h1 className='header'>About</h1>
            <hr className="about-left-spacer about-right-spacer"/>
            {/** ABOUT THIS TOOL */}
            <Row className='pb-4'>
                <Col className="d-flex flex-column justify-content-center about-left-spacer pe-0 me-0">
                    <h6 className='about-subheader'>About This Tool</h6>
                    <p className='subtext'>
                        Welcome to the <b>De</b>velopment <b>B</b>ank <b>I</b>nvestment <b>T</b>racker (DeBIT)!
                        This tool is designed to help you research projects financed by development finance
                        institutions with independent accountability mechanisms. The tool provides quick access
                        to key information on thousands of projects financed around the world, as well as data
                        on community complaints against these projects. The tool is designed to provide greater
                        transparency to the opaque world of development finance.
                    </p>
                    <p className='subtext'>
                        The tool collects data on more than 250,000 projects and companies funded
                        by 17 finance institutions (16 development banks and Norway's
                        sovereign wealth fund). To explore these projects, click on the database
                        button below. In addition to this financial information, we also provide macro-data
                        on complaints filed against these projects with independent accountability
                        mechanisms. For more information, click the complaints button below.
                    </p>
                </Col>
                <Col className="photo-collage-col d-flex me-2">
                    <img className="about-photo-collage" src={landingPageCollage} alt="about page photo collage" />
                </Col>
            </Row>
            {/** NAVIGATION BUTTONS */}
            <Row className='pb-3'>
                <Col className="d-flex justify-content-center about-left-spacer">
                    <div className="grow">
                        <a href="/database" className="card-link">
                            <Card border="secondary" style={{ width: '18rem' }}>
                                <Card.Img className="card-image" variant="bottom" src={databasePageIcon} />
                                <Card.Footer className="text-muted card-text">Database</Card.Footer>
                            </Card>
                        </a>
                    </div>  
                </Col>
                <Col className="about-left-spacer">
                    <div className="grow">
                        <a href="/complaints" className="card-link">
                            <Card border="secondary" style={{ width: '18rem' }}>
                                <Card.Img className="card-image" variant="bottom" src={complaintsPageIcon} />
                                <Card.Footer className="text-muted card-text">Complaints</Card.Footer>
                            </Card>
                        </a>
                    </div>
                </Col>
            </Row>
            <hr className="about-left-spacer about-right-spacer"/>
            {/** BACKGROUND */}
            <Row>
                <Col className="d-flex flex-column about-left-spacer about-right-spacer">
                    <h6 className='about-subheader'>Background</h6>
                    <p className='subtext'>
                        This tool was developed by{' '}
                        <Link
                            href="https://www.inclusivedevelopment.net/"
                            text="Inclusive Development International"
                        />
                        {' '}and the{' '}
                        <Link
                            href="https://www.datascience.uchicago.edu"
                            text="University of Chicago Data Science Institute"
                        />. 
                        It is designed to help community advocates track investments made
                        by development finance institutions and other entities that have independent accountability mechanisms.
                        This tool is a part of an open source initiative at the Data Science Institute and will
                        continue to be maintained by the DSI and contributors from around the world.
                    </p>
                    <p className='subtext'>
                        The database scrapes every investment project that is publicly disclosed by the following 17 institutions:
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column about-left-spacer about-right-spacer">
                    <ul className='subtext'>
                    {
                        bankNames.slice(0, lengthFirstNameCol + 1).map((bank, idx) => {
                            return (
                                <li key={idx}>{bank}</li>
                            );
                        })
                    }
                    </ul>
                    <p></p>
                </Col>
                <Col className="d-flex flex-column about-left-spacer about-right-spacer">
                    <ul className='subtext'>
                    {
                        bankNames.slice(lengthFirstNameCol + 1).map((bank, idx) => {
                            return (
                                <li key={idx}>{bank}</li>
                            );
                        })
                    }
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column about-left-spacer about-right-spacer">
                    <p className='subtext'>
                        The{' '}
                        <Link
                            href="/complaints"
                            text="Complaints"
                        />
                        {' '}tab contains aggregated data on complaints
                        filed to independent accountability mechanisms. It also shows overall investment
                        trends according to region, country, and sector. For advice on how to follow the
                        money behind harmful investment projects – including how to uncover development 
                        finance institutions and target them in advocacy – see the Follow the Money to Justice{' '}
                        <Link
                            href="https://www.followingthemoney.org/"
                            text="website"
                        />, which will launch in early May.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
