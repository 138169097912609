/**
 * DataTable.
 *
 * Table for viewing, searching, filtering, and downloading
 * development bank project data. Uses DevExtreme React Grid.
 */

import './DataTable.css';
import Spinner from 'react-bootstrap/Spinner';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { 
    CustomPaging,
    FilteringState,
    PagingState,
    SearchState,
    SortingState } from '@devexpress/dx-react-grid';
import { 
    GridExporter } from '@devexpress/dx-react-grid-export';
import {
    ColumnChooser,
    ExportPanel,
    Grid, 
    PagingPanel,
    SearchPanel,
    Table,
    TableColumnVisibility,
    TableFilterRow, 
    TableHeaderRow,
    Toolbar, 
    VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { GetProjects } from '../../../services/ApiClient';
import { currencyFormatter, numberFormatter } from '../../../services/Utilities';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import saveAs from 'file-saver';


export default function DataTable() {

    /*** STATE */
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState([{ columnName: 'name', direction: 'asc' }]);
    const [searchValue, setSearchValue] = useState('');
    const [pageSizes] = useState([10, 25, 50, 100]);
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsToExport, setRowsToExport] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            name: 'bank',
            title: 'Bank',
            getCellValue: row => row['bank_name']
        },
        {
            name: 'name',
            title: 'Name',
            getCellValue: row => row['name']
        },
        {
            name: 'status',
            title: 'Status',
            getCellValue: row => row['status']
        },
        {
            name: 'date',
            title: 'Date',
            getCellValue: row => {
                let dateStr = '';
                if (row['year'] !== null && row['year'] !== ''){
                    dateStr += String(row['year'])
                }
                if (row['month'] !== null && row['month'] !== ''){
                    dateStr += `-${String(row['month']).padStart(2, '0')}`
                }
                if (row['day'] !== null && row['day'] !== ''){
                    dateStr += `-${String(row['day']).padStart(2, '0')}`
                }
                return dateStr;
            }
        },
        {
            name: 'originalLoanAmount',
            title: 'Original Loan Amount',
            getCellValue: row => {
                let formattedLoanAmount = numberFormatter.format(row['loan_amount']);
                return `${formattedLoanAmount} ${row['loan_amount_currency']}`;
            }
        },
        {
            name: 'loanAmountInUsd',
            title: 'Loan Amount in USD',
            getCellValue: row => currencyFormatter.format(row['loan_amount_in_usd'])
        },
        {
            name: 'countries',
            title: 'Countries',
            getCellValue: row => {
                let finalCountryList = [];
                let rawCountries = row['country_list_raw']?.split(', ') ?? [];
                let stndCountries = row['country_list_stnd']?.split(', ') ?? [];

                for (let i = 0; i < rawCountries.length; i++){
                    if (['Global', 'Regional'].includes(rawCountries[i])){
                        finalCountryList.push(rawCountries[i]);
                    }
                    else if (['Regional', 'Unknown'].includes(stndCountries[i])){
                        finalCountryList.push(`${stndCountries[i]} - ${rawCountries[i]}`)
                    }
                    else {
                        finalCountryList.push(stndCountries[i])
                    }
                }

                return finalCountryList.join(', ');
            }
        },
        {
            name: 'sectors',
            title: 'Sectors',
            getCellValue: row => {
                let finalSectorList = [];
                let rawSectors = row['sector_list_raw']?.split(', ') ?? [];
                let stndSectors = row['sector_list_stnd']?.split(', ') ?? [];

                for (let i = 0; i < rawSectors.length; i++){
                    if (stndSectors[i] === 'Unknown'){
                        finalSectorList.push(`${stndSectors[i]} - ${rawSectors[i]}`);
                    }
                    else {
                        finalSectorList.push(stndSectors[i]);
                    }
                }

                return finalSectorList.join(', ');
            }
        },
        {
            name: 'companies',
            title: 'Companies',
            getCellValue: row => row['companies']
        },
        
    ]);
    const [tableColumnExtensions] = useState([
        { columnName: 'bank', width: '10%' },
        { columnName: 'name', width: '20%', wordWrapEnabled: true },
        { columnName: 'status', width: '10%' },
        { columnName: 'date', width: '10%' },
        { columnName: 'originalLoanAmount', width:'20%' },
        { columnName: 'loanAmountInUsd', width:'20%' },
        { columnName: 'countries', width: '15%', wordWrapEnabled: true },
        { columnName: 'sectors', width: '15%', wordWrapEnabled: true },
        { columnName: 'companies', width: '15%', wordWrapEnabled: true }
    ]);
    const [hiddenColumnNames, setHiddenColumnNames] = useState([
        'companies',
        'originalLoanAmount'
    ]);
    const exporterRef = useRef();
    const filterRef = useRef(null);
    const [filteringStateColumnExtensions] = useState([
        { columnName: 'originalLoanAmount', filteringEnabled: false },
        { columnName: 'loanAmountInUsd', filteringEnabled: false },
        { columnName: 'date', filteringEnabled: false }
    ]);

    /*** FUNCTIONS FOR FETCHING PROJECTS */
    const buildQuery = () => {
        let colFilters = filters.reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
        }, {});
        let query = {
            ...colFilters,
            download: 0,
            limit: pageSize,
            offset: pageSize * currentPage,
            search: searchValue,
            sortCol: sorting[0].columnName,
            sortDesc: sorting[0].direction === 'desc' ? 1 : 0
        }
        filterRef.current = query;
        return query;
    }
    
    const fetchProjects = () => {
        let currentQuery = buildQuery();    
        setLoading(true);
		GetProjects(currentQuery).then(json => {
            setRows(json['projects']);
            setTotalCount(json['total_num_rows']);
    		setLoading(false);
        })
        .catch(() => setLoading(false));
	};

    
    useEffect(() => {
        fetchProjects();
    }, [currentPage, pageSize, searchValue, sorting, filters]);

    /*** FUNCTIONS FOR UPDATING TABLE STATE */
    const processTableChange = (func, timeout = 400) => {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
              func.apply(this, args);
              setCurrentPage(0);
            }, timeout);
        };
    };
    
    /*** FUNCTIONS FOR EXPORTING PROJECTS TO FILE */
    const startExport = useCallback((options) => {
        let query = filterRef.current;
        query.download = 1;
        delete query.limit;
        delete query.offset;

        setLoading(true);
        GetProjects(query).then((text) => {
            setRowsToExport(text);
            setTimeout(() => exporterRef.current.exportGrid());
        })
    }, [exporterRef]);

    const debounceExport = (func, timeout = 500) => {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
              func.apply(this, args);
            }, timeout);
        };
    };

    const onSave = (workbook) => {
        workbook.csv.writeBuffer().then((buffer) => {
            let filename = 'projects.csv';
            saveAs(new Blob([buffer], { type: 'application/csv' }), filename);
            setLoading(false);
        });
    };

    /*** SUB-COMPONENTS */
    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            className="pointer"
            onDoubleClick ={() => {
                if (row['url'] !== null && row['url'] !== ''){
                    window.open(row['url']);
                };
            }}
        />
    );

    /*** RENDER MAIN COMPONENT */
    return (
        <div >
            <h5>
                {numberFormatter.format(totalCount)} record(s)
            </h5>
            <Grid
                rows={rows}
                columns={columns}
            >
                <SearchState
                    onValueChange={processTableChange(setSearchValue)}
                />
                <SortingState
                    sorting={sorting}
                    onSortingChange={processTableChange(setSorting, 10)}
                />
                <FilteringState
                    columnExtensions={filteringStateColumnExtensions}
                    onFiltersChange={processTableChange(setFilters)}
                />
                <PagingState
                    defaultCurrentPage={0}
                    defaultPageSize={10}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CustomPaging
                    totalCount={totalCount}
                />
                <VirtualTable />
                <Table 
                    columnExtensions={tableColumnExtensions}
                    rowComponent={TableRow}/>
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <PagingPanel
                    pageSizes={pageSizes}
                 />
                <Toolbar />
                <SearchPanel />
                <ColumnChooser />
                <ExportPanel startExport={startExport} />
                {
                    loading && <Spinner animation="border" variant="primary" />
                }
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rowsToExport}
                columns={columns}
                onSave={debounceExport(onSave)}
            />
        </div>
    );
}
