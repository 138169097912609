/**
 * ApiClient.
 *
 * Functions for retrieving data from REST API endpoints.
 *
 * @author Launa Greer
 * @since  08.17.2021
*/

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export async function GetComplaintsReport(){
    let uri = encodeURI(`${baseUrl}/api/complaints/complaint-report`);
    return await GetFromApi(uri);
}

export async function GetComplaintsGeojson(){
    let uri = encodeURI(`${baseUrl}/api/complaints/complaint-map`);
    return await GetFromApi(uri);
}

export async function GetInvestments(query){
    let uri = encodeURI(`${baseUrl}/api/form13f/investments/search`);
    return await PostToApi(uri, query);
}

export async function GetProjects(query){
    let uri = encodeURI(`${baseUrl}/api/projects/search`);
    return await PostToApi(uri, query);
}

async function GetFromApi(url) {
    try {
        const response = await fetch(url, {"method": "GET" });
        return await response.json();
    } 
    catch (err) {
        console.log(err);
    }
}

async function PostToApi(url, data) {
    try {
        const response = await fetch(url, {
            "headers": {
                "Content-Type": "application/json"
            },
            "body": JSON.stringify(data),
            "method": "POST"
        });
        return await response.json();
    } 
    catch (err) {
        console.log(err);
    }
}