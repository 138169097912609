import './App.css';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import NavigationBar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import ComplaintsReportPage from '../../complaints-report/page/ComplaintsReportPage';
import ProjectPage from '../../projects/page/ProjectPage';
import AboutPage from '../../about/AboutPage';
import InvestmentPage from '../../investments/page/InvestmentPage';


export default function App() {
  return (
    <div className="appContainer">
      <NavigationBar></NavigationBar>
      <div className="appContent">
        <Router>
          <Route path='/about' exact component={AboutPage}/>
          <Route path='/database' exact component={ProjectPage}/>
          <Route path='/complaints' exact component={ComplaintsReportPage}/>
          <Route path='/shareholders' exact component={InvestmentPage}/>
          <Route path='/' exact><Redirect to="/about" /></Route>
        </Router>
      </div>
      <Footer></Footer>
    </div>
  );
}
