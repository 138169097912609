import './Navbar.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

export default function NavigationBar() {
    return (
        <Navbar className="debit-navbar">
            <Container fluid={true}>
                <Navbar.Brand className="logoWrapper" href="/">
                    DeBIT
                </Navbar.Brand>
                <Nav className="ml-auto mx-5">
                    <Nav.Link href="/about" className="px-2">About</Nav.Link>
                    <Nav.Link href="/database" className="px-2">Database</Nav.Link>
                    <Nav.Link href="/complaints" className="px-2">Complaints</Nav.Link>
                    <Nav.Link href="/shareholders" className="px-2">Shareholder Tracker</Nav.Link>
                    <Nav.Link href="https://github.com/chicago-cdac/debit-scrapers" className="px-2">Contribute</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}
