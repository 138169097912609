import Plot from 'react-plotly.js';

export default function SectorFundingHistogram({ sectors }){

  return <Plot
      data={[
        {
          x: sectors.map(s => s['total_funded_investments_in_usd']).reverse(),
          y: sectors.map(s => s['total_funded_investments_in_usd']).reverse(),
          name: 'Distribution of Project Funding Amounts',
          type: 'histogram',
          orientation: 'v',
          marker: {
            color: 'rgb(56,108,176)'
          }
        },
      ]}
      layout={{
          xaxis: {
            title: '<b>Funds Received in Current USD ($)</b>', 
            font: {
                family: 'Arial',
            },
          },
          yaxis: {
            title: '<b>Count</b>', 
            font: {
                family: 'Arial',
            },
            dtick: 1,
            range: [1, 2, 3, 4],
          },
          height: 400,
          width: 600,
          showlegend: false,
          margin: {
            l: 40,
            r: 50,
            b: 50,
            t: 10,
            pad: 4
          }
      }}
    />
}