import Col from 'react-bootstrap/Col';
import IssueProject from '../../complaints-report/issue-project/IssueProject';


export default function IssueProjectsList({ issues }){

    let maxNumIssues = 10;
    let numIssues = Math.min(issues.length, maxNumIssues);
    let numIssuesPerCol = Math.ceil(numIssues / 2);

    return (
        <>
           <Col>
                {
                    issues.slice(0, numIssuesPerCol).map((issue, index) => {
                       return (
                            <IssueProject
                                issue={issue}
                                index={index + 1}>
                            </IssueProject>
                       )  
                    })
                }
            </Col>
            <Col>
                {
                    issues.slice(numIssuesPerCol, numIssues).map((issue, index) => {
                       return (
                            <IssueProject
                                issue={issue}
                                index={index + numIssuesPerCol + 1}>
                            </IssueProject>
                       )  
                    })
                }
            </Col>
        </>
    )
  }