import './IssueProject.css';
import biodiversityLogo from '../../../assets/biodiversity.png';
import consultLogo from '../../../assets/consult.png';
import culturalHeritageLogo from '../../../assets/cultural_heritage.png';
import displacementLogo from '../../../assets/displacement.png';
import dueDilligenceLogo from '../../../assets/due_dilligence.png';
import environmentLogo from '../../../assets/environment.png';
import fraudLogo from '../../../assets/fraud.png';
import genderLogo from '../../../assets/gender.png';
import genderBasedViolenceLogo from '../../../assets/gender_violence.png';
import healthLogo from '../../../assets/health.png';
import humanRightsLogo from '../../../assets/human_rights.png';
import indigenousLogo from '../../../assets/indigenous.png';
import laborLogo from '../../../assets/labor.png';
import livelihoodsLogo from '../../../assets/livelihoods.png';
import otherLogo from '../../../assets/other.png';
import personnelLogo from '../../../assets/personnel.png';
import pollutionLogo from '../../../assets/pollution.png';
import procurementLogo from '../../../assets/procurement.png';
import propertyDamageLogo from '../../../assets/property_damage.png';
import retaliationLogo from '../../../assets/retaliation.png';
import unknownLogo from '../../../assets/question_mark.png';
import violenceLogo from '../../../assets/violence.png';
import waterLogo from '../../../assets/water.png';


export default function IssueProject({ issue, index }){
    
    let logo = '';

    switch (issue.name.toLowerCase()){
        case 'biodiversity':
            logo = biodiversityLogo;
            break;
        case 'community health and safety':
            logo = healthLogo;
            break;
        case 'consultation and disclosure':
            logo = consultLogo;
            break;
        case 'corruption/fraud':
            logo = fraudLogo;
            break;
         case 'cultural heritage':
            logo = culturalHeritageLogo;
            break;
        case 'displacement (physical and/or economic)':
            logo = displacementLogo;
            break;
        case 'due diligence':
            logo = dueDilligenceLogo;
            break;
         case 'environmental':
            logo = environmentLogo;
            break;
        case 'gender-based violence':
            logo = genderBasedViolenceLogo;
            break;
        case 'gender-related':
            logo = genderLogo;
            break;
        case 'human rights':
            logo = humanRightsLogo;
            break;
        case 'indigenous peoples':
            logo = indigenousLogo;
            break;
        case 'labor':
            logo = laborLogo;
            break;
        case 'livelihoods':
            logo = livelihoodsLogo;
            break;
        case 'other':
            logo = otherLogo;
            break;
        case 'personnel':
            logo = personnelLogo;
            break;
        case 'pollution':
            logo = pollutionLogo;
            break;
         case 'procurement':
            logo = procurementLogo;
            break;
        case 'property damage':
            logo = propertyDamageLogo;
            break;
        case 'retaliation (actual or feared)':
            logo = retaliationLogo;
            break;
        case 'unknown':
            logo = unknownLogo;
            break;
        case 'violence against the community':
            logo = violenceLogo;
            break;
        case 'water':
            logo = waterLogo;
            break;
        default:
            logo = '';

    }

    return (
        <div className="issueProject">
            <img className="issueLogo" src={logo} alt={issue.name}></img>
            <span className="issueNumber">{index}</span>
            <span className="issueName">{issue.name} ({issue.total_num_complaints})</span>
        </div>
    )
}
