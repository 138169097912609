import './Link.css';

export default function Link({ href, text, openNewTab=true }) {
    return (
        <a
            className="text-link"
            href={href} 
            target={openNewTab ? "_blank" : "_self"}
        >
            {text}
        </a>
    )
}
