import './ProjectPage.css';
import DataTable from '../../projects/table/DataTable';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Link from '../../common/link/Link';


export default function ProjectPage() {

    return (
        <>
            <Container>
                <h1 className='header'>Development Bank Projects</h1>
                <Row>
                    <Col>
                        <p className='subtext'>
                            This database compiles every investment made by 17 development finance institutions
                            and other entities that have independent accountability mechanisms. For a full list
                            of institutions this tool scrapes, see the{' '}
                            <Link
                                href="/about"
                                text="About"
                                openNewTab={false}
                            />
                            {' '}tab.
                        </p>
                        <p className='subtext'>
                            To search on the name of a project or company, enter it into the main Search box
                            or Name field. You can also filter results using the Bank, Status, Countries or
                            Sector fields. Double click on a row in the search results to see the underlying
                            source.
                        </p>
                        <p className='subtext'>
                            This information is updated every two weeks.
                        </p>
                        <DataTable></DataTable>
                        <br/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
