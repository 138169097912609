import Plot from 'react-plotly.js';

export default function BankProjectBarPlot({ banks }){

  return <Plot
      data={[
        {
          x: banks.map(s => s['total_funded_investments_in_usd']).reverse(),
          y: banks.map(s => s['name']).reverse(),
          name: 'Total Funding Awarded Per Bank',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: 'rgb(190,174,212)'
          },
          xanchor: 'left',
        },
      ]}
      layout={{
          xaxis: {
                title: '<b>Total Funding in Current USD ($)</b>', 
                font: {
                    family: 'Arial',
                },
                xanchor: 'left',
                range: [0, 1600000000000],
          },
          xanchor: 'left',
          height: 400,
          width: 1200,
          showlegend: false,
          margin: {
            l: 500,
            r: 50,
            b: 80,
            t: 10,
            pad: 4
          }
      }}
    />
}