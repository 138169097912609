/**
 * Map.
 *
 * Allows users to hover over a country and view summary information.
 *
 * @author Daniel Grzenda, Launa Greer
 * @since  07.27.2021
 */

import './Map.css';
import MapPopup from '../map-popup/MapPopup';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';


export default function Map({ features }) {
  
    const geojsonStyler = (feature) => {
        let totalNumComplaints = parseFloat(feature['properties']['totalNumComplaints']);
        let totalNumProjects = parseFloat(feature['properties']['totalNumFundedProjects']);
        let scaleFactor = 10;
        let ratio = (totalNumComplaints / totalNumProjects) * scaleFactor;
        let complaintConcentration = totalNumProjects === 0 ? 0 : Math.min(ratio, 1);
		return {
			"weight": 1,
			"opacity": 0.5,
			"fillColor": "#FF0000",
	 		"fillOpacity": complaintConcentration
		};
	}

	const mouseoverEventHandler = (event) => {
		event.target.setStyle({
			"fillColor": "yellow",
            "fillOpacity": 0.8
		});
	}

	const mouseoutEventHandler = (event) => {
        event.target.resetStyle();
	}

    return (
        <MapContainer
            className='complaints-map'
            center={[-3.16, 17.53]}
            zoom={3} 
            scrollWheelZoom={true}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
                {
                    features.map( f => {
                        return (
                            <GeoJSON
                                data={f['geometry']}
                                key={f['properties']['id']}
                                style={geojsonStyler(f)}
                                eventHandlers={{
                                    mouseover: mouseoverEventHandler,
                                    mouseout: mouseoutEventHandler
                                }}
                            >
                                <MapPopup properties={f['properties']}></MapPopup>
                            </GeoJSON>
                        )
                    })
                }					
        </MapContainer>
	)
}