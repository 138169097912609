import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import idiLogo from '../../../assets/idi_logo.png';
import uchicagoDsiLogo from '../../../assets/uchicago_dsi_logo.png';

const Footer = () => {
  return (
    <>
      <Container fluid className="debitFooter">
        {/** QUICK LINKS */}
        <Row className="pt-3 px-5">
          <Col md={3}>
              <h4 className="footerSectionTitle">Quick Links</h4>
              <a href="/about">About</a><br/>
              <a href="/database">Database</a><br/>
              <a href="/complaints">Complaints</a><br/>
              <a href="/shareholders">Shareholder Tracker</a><br/>
              <a href="https://github.com/chicago-cdac/debit-scrapers">Contribute</a>
              <br/><br/>
          </Col>

          {/** SPACER */}
          <Col md={1}></Col>

          {/** PARTNERSHIPS */}
          <Col>
            <Row>
              <h6>DEVELOPED BY</h6>
              <Col>
                <a href="https://datascience.uchicago.edu/about/" target="_blank">
                  <img className="dsiLogo logo" src={uchicagoDsiLogo} alt="uchicago dsi logo"/>
                </a>
              </Col>
              <Col>
                <a href="https://www.inclusivedevelopment.net/" target="_blank">
                  <img className="idiLogo logo" src={idiLogo} alt="idi logo"/>
                </a>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <h6>
                  THIS PROJECT IS FUNDED BY THE{' '}
                  <span className="externalLink">
                    <a href="https://11thhourproject.org/" target="_blank">
                      11TH HOUR PROJECT
                    </a>
                  </span>.<br/>
                  COMPLAINTS DATA WAS PROVIDED BY{' '}
                  <span className="externalLink">
                    <a href="https://www.accountabilitycounsel.org/" target="_blank">
                      ACCOUNTABILITY COUNSEL
                    </a>
                  </span>.
                </h6>
              </Col>
            </Row>
          </Col>
        </Row>        
      </Container>
    </>
  );
}

export default Footer;
