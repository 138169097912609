import Plot from 'react-plotly.js';

export default function BankComplaintsBarPlot({ banks }){

  return <Plot
      data={[
        {
          x: banks.map(s => s['num_complaints_in_progress']).reverse(),
          y: banks.map(s => s['name']).reverse(),
          name: 'in progress',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: 'rgb(190,174,212)'
          }
        },
        {
          x: banks.map(s => s['num_complaints_closed_with_outcome']).reverse(),
          y: banks.map(s => s['name']).reverse(),
          name: 'closed w/ outcome',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: 'rgb(255,255,0)'
          }
        },
        {
          x: banks.map(s => s['num_complaints_closed_without_outcome']).reverse(),
          y: banks.map(s => s['name']).reverse(),
          name: 'closed w/o outcome',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: 'rgb(253,192,134)'
          }
        },
        {
          x: banks.map(s => s['num_complaints_closed_with_outside_outcome']).reverse(),
          y: banks.map(s => s['name']).reverse(),
          name: 'closed w/ outside outcome',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: 'rgb(69,212,255)'
          }
        },
        {
          x: banks.map(s => s['num_complaints_monitored']).reverse(),
          y: banks.map(s => s['name']).reverse(),
          name: 'monitored',
          type: 'bar',
          orientation: 'h',
          marker: {
            color: 'rgb(176,255,140)'
          }
        },
      ]}
      layout={{
          xaxis: {
            title: '<b>Count</b>', 
            font: {
                family: 'Arial',
            },
          },
          //yaxis: {title: 'Y axis'},
          height: 400,
          width: 1200,
          showlegend: true,
          barmode: 'stack',
          margin: {
            l: 500,
            r: 50,
            b: 50,
            t: 10,
            pad: 4
          },
          legend: {
            title: '<b>registration status</b>',
            x: 1.0,
            y: 0.5,
            bgcolor: 'rgba(255, 255, 255, 0)',
            bordercolor: 'rgba(255, 255, 255, 0)',
  },
      }}
    />
}

