/**
 * ComplaintsReportPage.js.
 *
 * Allows users to view complaint data summarized by
 * country, sector, bank, and issue type.
 *
 * @author Daniel Grzenda, Launa Greer
 * @since  07.27.2021
 */

import './ComplaintsReportPage.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Map from '../../complaints-report/map/Map';
import BankProjectsBarPlot from '../../complaints-report/bank-projects-bar-plot/BankProjectsBarPlot';
import BankFundingBarPlot from '../../complaints-report/bank-funding-bar-plot/BankFundingBarPlot';
import BankFundingLinePlot from '../../complaints-report/bank-funding-line-plot/BankFundingLinePlot';
import BankComplaintsBarPlot from '../../complaints-report/bank-complaints-bar-plot/BankComplaintsBarPlot';
import BankComplaintsLinePlot from '../../complaints-report/bank-complaints-line-plot/BankComplaintsLinePlot';
import BigLoader from '../../common/loader/BigLoader';
import IssueProjectsList from '../../complaints-report/issue-projects-list/IssueProjectsList';
import IssueComplaintsBarPlot from '../../complaints-report/issue-complaints-bar-plot/IssueComplaintsBarPlot';
import SectorComplaintsBarPlot from '../../complaints-report/sector-complaints-bar-plot/SectorComplaintsBarPlot';
import SectorFundingHistogram from '../../complaints-report/sector-funding-histogram/SectorFundingHistogram';
import SectorProjectBarPlot from '../../complaints-report/sector-project-bar-plot/SectorProjectBarPlot';
import { FaLink } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { GetComplaintsReport } from '../../../services/ApiClient';
import { GetComplaintsGeojson } from '../../../services/ApiClient';


export default function ComplaintsReportPage() {
    const [complaintsReport, setComplaintsReport] = useState(null);
    const [complaintsGeojson, setComplaintsGeojson] = useState(null);

    useEffect(() => {
        GetComplaintsReport().then(payload => {
            setComplaintsReport(payload);
        });
        GetComplaintsGeojson().then(geojson => {
			setComplaintsGeojson(geojson["features"]);
		});
	}, [])


    if (complaintsReport == null){
        return <BigLoader loadingText="Loading Report..." />;
    }

    if (complaintsGeojson == null){
        return <BigLoader loadingText="Loading Map..." />;
    }

    return (
        <Container className='reports-container'>
            <Row>
                <Col>
                    {/** PAGE TITLE */}
                    <h2 className='header'>COMPLAINT ANALYSIS</h2>

                    {/** DESCRIPTION */}
                    <p className='subtext'>
                        This page shows macro data on complaints filed to the independent accountability 
                        mechanisms of 17 development finance and other institutions. It also shows
                        overall trends in how these institutions invest their money, according to region,
                        country, and sector. This information has been compiled using the Accountability
                        Console developed by Accountability Counsel. Due to the low number of
                        complaints that were successfully linked with project records, as well as the wide
                        variety in economic sector names and variations in currency conversions, the
                        charts below should be considered an exploratory data analysis rather than
                        a definitive assessment of project-complaint and project-sector associations.
                        Please see the following note on methodology for more information.
                    </p>

                    {/** METHODOLOGY */}
                    <h6><b>METHODOLOGY</b></h6>
                    <p className='subtext'>
                        Accountability Counsel provided 1,395 historical complaint records in June 2021,
                        with original filing dates ranging from October 24, 1994, to October 26, 2020.
                        Of those records, 1,359 were made in relation to 13 of the 17 development banks
                        studied. Complaints for the remaining four - the Asian Infrastructure Investment
                        Bank (AIIB), the Belgian Investment Company for Developing Countries (BIO),
                        KfW Development Bank (KFW), and Norges Bank Investment Management (NBIM) - were
                        not available.     
                    </p>
                    <ul className='subtext'>
                        <li>African Development Bank (AfDB) - 94</li>
                        <li>Asian Development Bank - 174</li>
                        <li>German Investment Corporation (DEG), Netherlands Development Finance Company (FMO), and Proparco - 14</li>
                        <li>European Bank for Reconstruction and Development (EBRD) - 123</li>
                        <li>European Investment Bank (EIB) - 252</li>
                        <li>Inter-American Development Bank (IADB) - 157</li>
                        <li>IFC and MIGA - 365</li>
                        <li>United Nations Development Programme (UNDP) - 22</li>
                        <li>U.S. International Development Finance Corporation (DFC) - 16</li>
                        <li>World Bank (WB) - 142</li>
                    </ul>
                    <p className='subtext'>
                        These complaints were then linked to scraped development
                        bank project records in three steps. First, matrices of frequency-inverse document
                        frequency (tf-idf) values were computed for project names in both datasets.
                        Then the cosine similarity of the two matrices was calculated. Finally, record pairs
                        with similarity scores between 0.65 and 1.0 (perfect similarity) inclusive were
                        labeled as matches if they also had identical bank and country names. Pairs were also
                        considered matches if the similarity scores of their project names were between 0.96
                        and 1.0 inclusive and their bank names were identical.
                    </p>
                    <p className='subtext'>
                        Scraped project records were also manually mapped to one of the 13 economic
                        sectors defined by Accountability Counsel where possible. Only a small number of
                        the total records were successfully linked. Finally, a rudimentary currency
                        conversion was performed from the scraped projects' original loan amounts and
                        currencies into U.S. dollars. The conversion rate for the current day was
                        fetched from the Free Currency Converter API at the time of scraper execution.
                        Given that the conversion rate is variable and the dates of the original loan
                        amounts are not taken into consideration when performing the conversion,
                        calculations and comparisons of financing in USD should be viewed as approximations.
                    </p>

                    {/** COUNTRY OVERVIEW */}
                    <h4 id={"country"} className='subheader'>
                        COUNTRY OVERVIEW<a href="#country-overview"><FaLink color='#999999' size={15}/></a>
                    </h4>
                    <p className='subtext'>
                        Hover over a country to see the total number of projects as well
                        as the estimated total investment amount in USD and the number of filed
                        complaints that were successfully linked to projects in that country.
                    </p>
                    <Map features={complaintsGeojson}></Map>

                    {/** PROJECT OVERVIEW */}
                    <h4 id={"project-overview"} className='subheader'>
                        PROJECT OVERVIEW<a href="#project-overview"><FaLink color='#999999' size={15}/></a>
                    </h4>
                    <p className='subtext'>
                        The first two graphs below show projects and investment amounts by
                        economic sector. As stated above, only a subset of the total
                        number of projects were successfully mapped to the standardized
                        sectors used by Accountability Counsel. Moreover, the currency
                        exchange rates used to convert between the investment banks'
                        original loan amounts and the amounts in current U.S. dollars are
                        inconsistent for each scraped project record, so trends in
                        funding are necessarily inaccurate.
                        The next three graphs show projects and investment amounts by
                        bank. While all scraped project records are associated with
                        one of 17 development finance institutions, the same caveat
                        regarding inconsistencies in USD funding amounts applies here.
                    </p>
                    <Row>
                        <Col>
                            <h6>TOTAL NUMBER OF PROJECTS PER SECTOR</h6>
                            <SectorProjectBarPlot sectors={complaintsReport['sectors']}></SectorProjectBarPlot>
                        </Col>
                        <Col>
                            <h6>DISTRIBUTION OF PROJECT FUNDING AMOUNTS</h6>
                            <SectorFundingHistogram sectors={complaintsReport['sectors']}></SectorFundingHistogram>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>TOTAL NUMBER OF PROJECTS PER BANK</h6>
                            <BankProjectsBarPlot banks={complaintsReport['banks']}></BankProjectsBarPlot>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>TOTAL FUNDING AWARDED PER BANK</h6>
                            <BankFundingBarPlot banks={complaintsReport['banks']}></BankFundingBarPlot>
                        </Col>
                    </Row>
                    <Row>
                        <h6>TOTAL AWARDED FUNDING PER BANK PER YEAR</h6>
                        <BankFundingLinePlot banks={complaintsReport['banks']}></BankFundingLinePlot>
                    </Row>

                    {/** COMPLAINTS ANALYSIS */}
                    <h4 id={"complaints-overview"} className='subheader'>
                        Complaints Overview<a href="#complaints-overview"><FaLink color='#999999' size={15}/></a>
                    </h4>

                    <p className='subtext'>
                        The first three graphs in this section aggregate the total number of
                        complaints by sector, bank, and bank per year.
                        Again, as a caveat, only a subset of the complaints provided
                        by Accountability Counsel were successfully linked to scraped
                        projects using record linkage, and not all projects were linked
                        to sectors. The final two graphs reflect all available complaints
                        and list counts of complaints by issue type, like pollution or
                        displacement. Each complaint may be associated with many issues
                        and vice versa.
                    </p>
                    <Row>
                        <h6>TOTAL NUMBER OF COMPLAINTS PER SECTOR</h6>
                         <SectorComplaintsBarPlot sectors={complaintsReport['sectors']}></SectorComplaintsBarPlot>
                    </Row>
                    <Row>
                        <h6>TOTAL NUMBER OF COMPLAINTS PER BANK</h6>
                        <BankComplaintsBarPlot banks={complaintsReport['banks']}></BankComplaintsBarPlot>
                    </Row>
                    <Row>
                        <h6>TOTAL NUMBER OF COMPLAINTS PER BANK PER YEAR</h6>
                        <BankComplaintsLinePlot banks={complaintsReport['banks']}></BankComplaintsLinePlot>
                    </Row>
                    <br/><br/>
                    <Row>
                        <h6>TOP 10 ISSUES BY NUMBER OF COMPLAINTS AFFECTED</h6>
                        <br/><br/>
                        <IssueProjectsList issues={complaintsReport['issues']}></IssueProjectsList>
                    </Row>
                    <br/>
                    <Row>
                        <h6>TOP 10 ISSUES BY NUMBER OF ASSOCIATED COMPLAINTS</h6>
                        <br/>
                        <IssueComplaintsBarPlot issues={complaintsReport['issues']}></IssueComplaintsBarPlot>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
