/**
 * DataTable.
 *
 * Table for viewing, searching, filtering, and downloading
 * Form 13F investment data. Uses DevExtreme React Grid.
 */

import './DataTable.css';
import Spinner from 'react-bootstrap/Spinner';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { 
    CustomPaging,
    FilteringState,
    PagingState,
    SearchState,
    SortingState } from '@devexpress/dx-react-grid';
import { 
    GridExporter } from '@devexpress/dx-react-grid-export';
import {
    ColumnChooser,
    ExportPanel,
    Grid, 
    PagingPanel,
    SearchPanel,
    Table,
    TableColumnVisibility,
    TableFilterRow, 
    TableHeaderRow,
    Toolbar, 
    VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { GetInvestments } from '../../../services/ApiClient';
import { currencyFormatter, numberFormatter } from '../../../services/Utilities';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import saveAs from 'file-saver';


export default function DataTable() {

    /*** STATE */
    const outputFileName = 'investments.csv';
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState([{ columnName: 'companyName', direction: 'asc' }]);
    const [searchValue, setSearchValue] = useState('');
    const [pageSizes] = useState([10, 25, 50, 100]);
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsToExport, setRowsToExport] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns] = useState([
        {
            name: 'issuerName',
            title: 'Company',
            getCellValue: row => row['issuer_name']
        },
        {
            name: 'ticker',
            title: 'Ticker',
            getCellValue: row => row['ticker']
        },
        {
            name: 'companyCik',
            title: 'Investor CIK',
            getCellValue: row => row['company_cik']
        },
        {
            name: 'companyName',
            title: 'Investor',
            getCellValue: row => row['company_name']
        },
        {
            name: 'numShares',
            title: 'Shares',
            getCellValue: row => {
                let numShares = numberFormatter.format(row['num_shares']);
                return numShares == 0 ? '' : numShares;
            }
        },
        {
            name: 'valuex1000',
            title: 'Value (USD)',
            getCellValue: row => {
                let value = parseInt(row['value_x1000'], 10) * 1000;
                return currencyFormatter.format(value);
            }
        },
        {
            name: 'formReportPeriod',
            title: 'Report Period',
            getCellValue: row => row['form_report_period']
        },
        {
            name: 'formAccessionNumber',
            title: 'Accession No.',
            getCellValue: row => row['form_accession_number']
        },
        {
            name: 'formFilingDate',
            title: 'Filing Date',
            getCellValue: row => row['form_filing_date']
        },
        {
            name: 'formAcceptanceDate',
            title: 'Accepted On',
            getCellValue: row => row['form_acceptance_date']
        },
        {
            name: 'formEffectiveDate',
            title: 'Effective On',
            getCellValue: row => row['form_effective_date']
        },
        {
            name: 'investmentDiscretion',
            title: 'Investment Discretion',
            getCellValue: row => row['investment_discretion']
        },
        {
            name: 'manager',
            title: 'Manager',
            getCellValue: row => row['manager']
        },
        {
            name: 'exchangeCode',
            title: 'Exchange',
            getCellValue: row => row['exchange_code']
        },
        {
            name: 'securityType',
            title: 'Security Type',
            getCellValue: row => row['security_type']
        },
        {
            name: 'cusip',
            title: 'CUSIP',
            getCellValue: row => row['cusip']
        },
        {
            name: 'titleClass',
            title: 'Title Class',
            getCellValue: row => row['title_class']
        },
        {
            name: 'marketSector',
            title: 'Market Sector',
            getCellValue: row => row['market_sector']
        },
        {
            name: 'principalAmount',
            title: 'Principal Amount',
            getCellValue: row => {
                let principalAmount = numberFormatter.format(row['principal_amount']);
                return principalAmount == 0 ? '' : principalAmount;
            }
        },
        {
            name: 'putCall',
            title: 'Option Type',
            getCellValue: row => {
                let put_call = row['put_call'];
                return 'None' ? put_call == null : put_call;
            }
        },
        {
            name: 'votingAuthSole',
            title: 'Sole Voting Authority',
            getCellValue: row => numberFormatter.format(row['voting_auth_sole'])
        },
        {
            name: 'votingAuthShared',
            title: 'Shared Voting Authority',
            getCellValue: row => numberFormatter.format(row['voting_auth_shared'])
        },
        {
            name: 'votingAuthNone',
            title: 'No Voting Authority',
            getCellValue: row => numberFormatter.format(row['voting_auth_none'])
        }
    ]);
    const [tableColumnExtensions] = useState([
        { columnName: 'companyCik', width: '10%' },
        { columnName: 'companyName', width: '15%', wordWrapEnabled: true },
        { columnName: 'formAccessionNumber', width: '20%' },
        { columnName: 'formReportPeriod', width: '15%' },
        { columnName: 'formFilingDate', width: '15%' },
        { columnName: 'formAcceptanceDate', width: '15%' },
        { columnName: 'formEffectiveDate', width: '15%' },
        { columnName: 'issuerName', width:'20%', wordWrapEnabled: true },
        { columnName: 'investmentDiscretion', width: '20%' },
        { columnName: 'manager', width: '12%', wordWrapEnabled: true },
        { columnName: 'exchangeCode', width: '15%' },
        { columnName: 'securityType', width:'15%' },
        { columnName: 'marketSector', width: '15%' },
        { columnName: 'cusip', width:'10%' },
        { columnName: 'ticker', width: '10%' },
        { columnName: 'titleClass', width: '20%' },
        { columnName: 'putCall', width: '15%' },
        { columnName: 'valuex1000', width: '15%' },
        { columnName: 'numShares', width: '15%' },
        { columnName: 'principalAmount', width: '20%' },
        { columnName: 'votingAuthSole', width: '20%' },
        { columnName: 'votingAuthShared', width: '22%' },
        { columnName: 'votingAuthNone', width: '20%' }
    ]);
    const [hiddenColumnNames, setHiddenColumnNames] = useState([
        'companyCik',
        'exchangeCode',
        'formAccessionNumber',
        'formFilingDate',
        'formAcceptanceDate',
        'formEffectiveDate',
        'titleClass',
        'marketSector',
        'putCall',
        'investmentDiscretion',
        'cusip',
        'securityType',
        'principalAmount',
        'manager',
        'votingAuthSole',
        'votingAuthShared',
        'votingAuthNone'
    ]);
    const exporterRef = useRef();
    const filterRef = useRef(null);
    const [filteringStateColumnExtensions] = useState([
        { columnName: 'manager', filteringEnabled: false },
        { columnName: 'valuex1000', filteringEnabled: false },
        { columnName: 'numShares', filteringEnabled: false },
        { columnName: 'principalAmount', filteringEnabled: false },
        { columnName: 'votingAuthSole', filteringEnabled: false },
        { columnName: 'votingAuthShared', filteringEnabled: false },
        { columnName: 'votingAuthNone', filteringEnabled: false }
    ]);
    const [sortingStateColumnExtensions] = useState([
        { columnName: 'exchangeCode', sortingEnabled: false },
        { columnName: 'manager', sortingEnabled: false },
    ]);

    /*** FUNCTIONS FOR FETCHING INVESTMENTS */
    const buildQuery = () => {
        let colFilters = filters.reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
        }, {});
        let query = {
            ...colFilters,
            download: 0,
            limit: pageSize,
            offset: pageSize * currentPage,
            search: searchValue,
            sortCol: sorting[0].columnName,
            sortDesc: sorting[0].direction === 'desc' ? 1 : 0
        }
        filterRef.current = query;
        return query;
    }
    
    const fetchReferences = () => {
        let currentQuery = buildQuery();    
        setLoading(true);
		GetInvestments(currentQuery).then(json => {
            setRows(json['investments']);
            setTotalCount(json['total_num_rows']);
    		setLoading(false);
        })
        .catch(() => setLoading(false));
	};

    
    useEffect(() => {
        fetchReferences();
    }, [currentPage, pageSize, searchValue, sorting, filters]);

    /*** FUNCTIONS FOR UPDATING TABLE STATE */
    const processTableChange = (func, timeout = 400) => {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
              func.apply(this, args);
              setCurrentPage(0);
            }, timeout);
        };
    };
    
    /*** FUNCTIONS FOR EXPORTING INVESTMENTS TO FILE */
    const startExport = useCallback((options) => {
        let query = filterRef.current;
        query.download = 1;
        delete query.limit;
        delete query.offset;

        setLoading(true);
        GetInvestments(query).then((text) => {
            setRowsToExport(text);
            setTimeout(() => exporterRef.current.exportGrid());
        })
    }, [exporterRef]);

    const debounceExport = (func, timeout = 500) => {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
              func.apply(this, args);
            }, timeout);
        };
    };

    const onSave = (workbook) => {
        workbook.csv.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/csv' }), outputFileName);
            setLoading(false);
        });
    };

    /*** SUB-COMPONENTS */
    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            className="pointer"
            onDoubleClick ={() => {
                if (row['form_url'] !== null && row['form_url'] !== ''){
                    window.open(row['form_url']);
                };
            }}
        />
    );

    /*** RENDER MAIN COMPONENT */
    return (
        <div >
            <h5>
                {numberFormatter.format(totalCount)} record(s)
            </h5>
            <Grid
                rows={rows}
                columns={columns}
            >
                <SearchState
                    onValueChange={processTableChange(setSearchValue)}
                />
                <SortingState
                    sorting={sorting}
                    columnExtensions={sortingStateColumnExtensions}
                    onSortingChange={processTableChange(setSorting, 10)}
                />
                <FilteringState
                    columnExtensions={filteringStateColumnExtensions}
                    onFiltersChange={processTableChange(setFilters)}
                />
                <PagingState
                    defaultCurrentPage={0}
                    defaultPageSize={10}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CustomPaging
                    totalCount={totalCount}
                />
                <VirtualTable />
                <Table 
                    columnExtensions={tableColumnExtensions}
                    rowComponent={TableRow}/>
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <PagingPanel
                    pageSizes={pageSizes}
                 />
                <Toolbar />
                <SearchPanel />
                <ColumnChooser />
                {/* <ExportPanel startExport={startExport} />*/}
                {
                    loading && <Spinner animation="border" variant="primary" />
                }
            </Grid>
            {/* <GridExporter
                ref={exporterRef}
                rows={rowsToExport}
                columns={columns}
                onSave={debounceExport(onSave)}
            /> */}
        </div>
    );
}
